<template>
  <v-container fluid class="container-main">
    <div class="blur-1"></div>
    <v-row justify="center" style="z-index: 1; position: relative;">
      <v-col cols="12" sm="11" md="11">
        <v-row justify="space-between">
          <v-col cols="12" sm="7" align-self="center">
            <v-row justify="start">
              <v-col class="pr-0 pr-sm-3" cols="1" sm="2" md="1" align-self="start">
                <svg width="100%" style="vertical-align: middle; padding-top: .5rem;" viewBox="0 0 35 35" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.5 0C14.0388 0 10.6554 1.02636 7.77753 2.94928C4.89967 4.87221 2.65665 7.60533 1.33212 10.803C0.00758248 14.0007 -0.338976 17.5194 0.336265 20.9141C1.01151 24.3087 2.67822 27.4269 5.12564 29.8744C7.57306 32.3218 10.6913 33.9885 14.0859 34.6637C17.4806 35.339 20.9993 34.9924 24.197 33.6679C27.3947 32.3433 30.1278 30.1003 32.0507 27.2225C33.9736 24.3446 35 20.9612 35 17.5C34.9951 12.8602 33.1498 8.41187 29.869 5.13104C26.5881 1.85022 22.1398 0.00489969 17.5 0ZM30.6873 10.7692H24.7255C24.0385 8.066 22.8848 5.50349 21.3163 3.19711C23.3202 3.73561 25.1891 4.68751 26.803 5.99161C28.4169 7.29571 29.74 8.92313 30.6873 10.7692ZM17.5 2.71082C19.5192 4.89832 21.0337 7.68317 21.9373 10.7692H13.0627C13.9663 7.68317 15.4808 4.90168 17.5 2.71082ZM12.1154 17.5C12.1156 16.1468 12.2282 14.7961 12.4519 13.4615H22.5481C22.9945 16.1353 22.9945 18.8647 22.5481 21.5385H12.4519C12.2282 20.2039 12.1156 18.8531 12.1154 17.5ZM13.0627 24.2308H21.9373C21.0337 27.3168 19.5192 30.0983 17.5 32.2892C15.4808 30.0983 13.9663 27.3168 13.0627 24.2308ZM21.3163 31.8029C22.8848 29.4965 24.0385 26.934 24.7255 24.2308H30.6873C29.74 26.0769 28.4169 27.7043 26.803 29.0084C25.1891 30.3125 23.3202 31.2644 21.3163 31.8029ZM25.2757 21.5385C25.6773 18.8611 25.6773 16.1389 25.2757 13.4615H31.7474C32.4945 16.102 32.4945 18.898 31.7474 21.5385H25.2757Z"
                    fill="#101010" />
                </svg>
              </v-col>
              <v-col cols="8" sm="10" md="11" align-self="center" class="fontSFProR title-text">
                {{ t(`$vuetify.home.languages.title`) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col offset="1" offset-sm="0" cols="10" md="4" lg="3" align-self="center"
            class="description-text fontSFProR">
            {{ t(`$vuetify.home.languages.text_right`) }}
          </v-col>
        </v-row>
        <v-row justify="end" class="mt-15">
          <v-col cols="11" md="11" lg="10">
            <v-row>
              <v-col cols="auto">
                <p class="fontSFProR" style="color: #737475;">
                  {{ t(`$vuetify.home.languages.we_speak_on`) }}
                </p>
              </v-col>
            </v-row>
            <v-divider class="divider-bottom mt-2 mb-4" color="info"></v-divider>
            <div v-for="(language, index) in languages" :key="language + index">
              <v-row justify="center" justify-sm="space-between">
                <v-col cols="5" sm="auto" align-self="center">
                  <p class="language-number fontSFProR">
                    {{ index > 9 ? index + 1 : '0' + (index + 1) }}
                  </p>
                </v-col>
                <v-col cols="7" sm="6" md="7" align-self="center">
                  <p class="language-title fontSFProR">
                    {{ language }}
                  </p>
                </v-col>
              </v-row>
              <v-divider class="divider-bottom mt-9 mb-4" color="info"></v-divider>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { useLocale } from "vuetify";

export default {
  name: 'LanguagesPage',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    languages: [
      "Deutsch",
      "English",
      "Русский",
      "Français",
      "Shqiptare",
      "Español",
      "Український",
      "Română",
    ]
  })
}
</script>

<style scoped>
.language-number {
  color: #9A9A9B;
  font-size: 1.1rem;
  letter-spacing: .08rem;
}

.language-title {
  color: #4C4C4C;
  font-size: 1.3rem;
}

.blur-1 {
  width: 300px;
  height: 600px;
  position: absolute;
  top: -30%;
  left: 90%;
  background: #EEAC47;
  -webkit-backdrop-filter: blur(200px);
  filter: blur(200px);
  border-radius: 100%;
  will-change: transform;
  z-index: 0;
  animation-name: blur-1-anim;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


@keyframes blur-1-anim {
  from {
    background: #EEAC47;
  }

  to {
    background: #FE4F32;
  }
}

.divider-bottom {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

.title-text {
  font-size: 1.3rem;
  color: #000000;
}

.description-text {
  font-size: 1rem;
  color: #101010;
}

.container-main {
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
}
</style>
