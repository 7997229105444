import en from "./en.js";
import de from "./de.js";

let lang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "de";

export const locale = {
  locale: lang,
  fallback: "de",
  globalInjection: true,
  messages: { en, de },
};
