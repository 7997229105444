import { createStore } from "vuex";
import Categories from "@/assets/categories.json";
import Cars from "@/assets/cars.json";
import Jets from "@/assets/jets.json";

export default createStore({
  state: {
    AllCategories: Categories,
    AllCars: Cars,
    AllJets: Jets,
  },
  getters: {
    getAllCategories: (state) => state.AllCategories,
    getAllCars: (state) => state.AllCars,
    getAllJets: (state) => state.AllJets,
  },
  mutations: {},
  actions: {},
  modules: {},
});
