<template>
  <headerTop />
  <Preloader />
  <HelloPage />
  <Discover />
  <Parallax />
  <HiTechServices />
  <Languages />
  <Products />
  <Approach />
  <Pricing />
  <Navigation />
  <FooterComponent />
</template>

<script>
import { defineComponent } from 'vue'
import Preloader from "../components/Preloader.vue";
import headerTop from "../components/header.vue";
import HelloPage from '../components/Home/1.HelloPage.vue'
import Discover from '../components/Home/2.Discover.vue'
import Parallax from '../components/Home/3.Parallax.vue';
import HiTechServices from '../components/Home/4.HiTechServices.vue';
import Languages from '../components/Home/5.Languages.vue';
import Products from '../components/Home/6.Products.vue';
import Approach from '../components/Approach.vue';
import Pricing from '../components/Service/7.PricingPage.vue';
import Navigation from '../components/Navigation.vue';
import FooterComponent from '../components/footer.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    headerTop,
    Preloader,
    HelloPage,
    Discover,
    Parallax,
    HiTechServices,
    Languages,
    Products,
    Approach,
    Pricing,
    Navigation,
    FooterComponent,
  },
})
</script>
