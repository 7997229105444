<template>
  <headerTop />
  <Preloader />
  <ContactUs />
  <GetInTouch />
  <GreenTeam />
  <Navigation />
  <FooterComponent />
</template>

<script>
import Preloader from "../components/Preloader.vue";
import headerTop from "../components/header.vue";
import ContactUs from "../components/Contact/1.ContactUs.vue";
import GetInTouch from "../components/Contact/2.GetInTouch.vue";
import GreenTeam from '../components/GreenTeam.vue';
import Navigation from '../components/Navigation.vue';
import FooterComponent from '../components/footer.vue';


export default {
  name: 'ContactView',
  components: {
    Preloader,
    headerTop,
    ContactUs,
    GetInTouch,
    GreenTeam,
    Navigation,
    FooterComponent,
  },
}
</script>
