<template>
    <ContactUsModal ref="OpenContactUsModal" />
    <v-container fluid class="container-main">
        <v-container fluid class="pa-0">
            <v-row justify="center">
                <v-col cols="12" md="11">
                    <v-divider class="divider-bottom mb-9 mb-0" color="info"></v-divider>
                    <h3 class="block-title fontSFProR">
                        {{ t("$vuetify.contact.personal_request.title") }}
                    </h3>
                    <p class="block-text fontSFProR">
                        {{ t("$vuetify.contact.personal_request.text") }}
                    </p>
                    <v-btn @click="OpenModal()" class="btn-write-message fontSFProR mt-8" size="large" block
                        color="#E6E8EB">
                        {{ t("$vuetify.contact.personal_request.btn_messege") }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import { useLocale } from "vuetify";
import ContactUsModal from "../Modals/ContactUsModal.vue";

export default {
    name: 'GetInTouchPage',
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    components: {
        ContactUsModal
    },
    methods: {
        OpenModal() {
            this.$refs.OpenContactUsModal.dialog = true;
            //this.$refs.OpenContactUsModal.property = card;
        },
    }
}
</script>
<style scoped>
.btn-write-message {
    border-radius: 15px;
    box-shadow: 5px 18px 50px 0px rgba(0, 0, 0, 0.25);
    color: #101010;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: inherit;
    letter-spacing: 0px;
}

.block-text {
    color: #9A9A9B;
    font-size: 1.7rem;
    font-weight: 400;
}

.block-title {
    color: #E6E8EB;
    font-size: 3.2rem;
    font-weight: 400;
    text-transform: uppercase;
}

.divider-bottom {
    border-width: 1px;
    border-color: rgba(154, 154, 155, 0.36);
    opacity: 1;
}

.container-main {
    min-width: 100vw;
    background: #101010;
    padding-top: 5vh;
    padding-bottom: 12vh;
    position: relative;
    z-index: 1;
    margin-top: -1px;
    border-radius: 0px 0px 25px 25px;
    box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.25);
}



@media (min-width: 600px) and (max-width: 960px) {}

@media (max-width: 600px) {}
</style>