<template>
  <v-container fluid class="container-main">
    <v-row justify="center">
      <v-col cols="12" sm="11" md="11">
        <v-row justify="center">
          <v-col cols="12">
            <h2 class="text-title">
              {{ t("$vuetify.home.discover.title.part1") }}
              <br />
              {{ t("$vuetify.home.discover.title.part2") }}
            </h2>
          </v-col>
        </v-row>
        <v-row justify="center" class="row2-margin">
          <v-col cols="9" sm="6" md="4" lg="3">
            <!-- <v-img class="discover-img" src="../../assets/img/Home/Discover.jpg"
              lazy-src="../../assets/img/Home/lazy/Discover.jpg" alt="Porsche 911"></v-img> -->
            <video class="discover-img" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline"
              :poster="require('../../assets/img/Home/Discover.jpg')" title="Luxurious cars" alt="Luxurious cars"
              width="100%" height="auto">
              <source type="video/mp4" :src="require('../../assets/img/Home/Discover.mp4')">
              <source type="video/webm" :src="require('../../assets/img/Home/Discover.webm')">
            </video>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mt-15">
          <v-col cols="12" sm="7">
            <v-row justify="start">
              <v-col class="pr-0 pr-sm-3" cols="1" sm="2" md="1" align-self="start">
                <svg width="100%" style="vertical-align: middle; padding-top: .5rem;" viewBox="0 0 40 35" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M39.3806 2.17401L24.1777 34.1C24.095 34.2851 23.9761 34.4518 23.8279 34.5903C23.6798 34.7288 23.5055 34.8362 23.3152 34.9063C23.125 34.9764 22.9226 35.0077 22.72 34.9984C22.5175 34.9891 22.3188 34.9394 22.1358 34.8522C21.9527 34.765 21.789 34.642 21.6542 34.4906C21.5194 34.3391 21.4162 34.1622 21.3508 33.9703C21.2853 33.7784 21.259 33.5753 21.2732 33.3731C21.2874 33.1708 21.3419 32.9734 21.4336 32.7925L26.9142 21.284H1.52166C1.22598 21.2842 0.936673 21.1981 0.689149 21.0364C0.441625 20.8747 0.246632 20.6443 0.128046 20.3734C0.00946047 20.1026 -0.0275709 19.803 0.0214864 19.5115C0.0705438 19.2199 0.203561 18.9489 0.404249 18.7318L8.49217 9.97117L0.493566 2.6415C0.267525 2.43451 0.109327 2.16403 0.0397224 1.86554C-0.029882 1.56705 -0.00764166 1.25449 0.103526 0.96887C0.214693 0.683245 0.409596 0.437891 0.662669 0.264992C0.915741 0.0920933 1.21516 -0.000276241 1.52166 6.20563e-07H38.0085C38.2651 8.50178e-05 38.5176 0.0651342 38.7423 0.189087C38.967 0.313039 39.1567 0.491861 39.2937 0.708887C39.4306 0.925914 39.5104 1.17408 39.5256 1.43027C39.5408 1.68645 39.4909 1.94231 39.3806 2.17401Z"
                    fill="#101010" />
                </svg>
              </v-col>
              <v-col cols="10" sm="10" md="11" align-self="center" class="fontSFProR title-text">
                {{ t("$vuetify.home.discover.contact_us_text.part1") }}
                <br />
                {{ t("$vuetify.home.discover.contact_us_text.part2") }}
              </v-col>
            </v-row>
          </v-col>
          <v-col offset="1" offset-sm="0" cols="10" md="4" lg="3" align-self="center"
            class="description-text fontSFProR  pl-3 pr-6 px-sm-0">
            {{ t("$vuetify.home.discover.text_right") }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: 'Discover',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
}
</script>

<style scoped>
.title-text {
  font-size: 1.3rem;
  color: #000000;
}

.description-text {
  font-size: 1rem;
  color: #101010;
}

.row2-margin {
  margin-top: -65px;
}

.discover-img {
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.13);
}

.text-title {
  text-transform: uppercase;
  color: #343330;
  font-weight: 300;
  font-size: 5.5rem;
  line-height: 7rem;
  text-align: center;
}

.container-main {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 600px) {
  .text-title {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3rem;
  }

  .row2-margin {
    margin-top: -37px;
  }
}
</style>
