<template>
  <v-expand-transition>
    <div class="preloader" v-if="loading">
      <v-row justify="center">
        <v-col v-if="$vuetify.display.smAndUp" cols="auto" align-self="center">
          <svg style="vertical-align: middle;" width="18" viewBox="0 0 18 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 9C18 10.78 17.4722 12.5201 16.4832 14.0001C15.4943 15.4802 14.0887 16.6337 12.4442 17.3149C10.7996 17.9961 8.99002 18.1743 7.24419 17.8271C5.49836 17.4798 3.89471 16.6226 2.63604 15.364C1.37737 14.1053 0.520203 12.5016 0.172936 10.7558C-0.17433 9.00998 0.00389956 7.20038 0.685088 5.55585C1.36628 3.91131 2.51983 2.50571 3.99987 1.51677C5.47991 0.527841 7.21997 0 9 0C11.386 0.00297696 13.6735 0.952144 15.3607 2.63933C17.0479 4.32651 17.997 6.61396 18 9Z"
              fill="#865000" />
          </svg>
        </v-col>
        <v-col cols="auto" align-self="center">
          <h3 class="text-h3-preloader fontSFProR">
            {{ selected }}
          </h3>
        </v-col>
      </v-row>
    </div>
  </v-expand-transition>
</template>

<script>
export default {
  name: "PreloaderPage",
  props: {
    isLoading: Boolean,
  },
  data: () => ({
    words: ["THE FUTURE OF COMFORT", "E ARDHMJA E KOFORTIT", "БУДУЩЕЕ КОМФОРТА", "EL FUTURO DEL CONFORT", "VIITORUL CONFORTULUI", "МАЙБУТНЄ КОМФОРТУ", "IL FUTURO DEL COMFORT"],
    selected: "",
    lap: false,
  }),
  mounted() {
    this.selected = "DIE ZUKUNFT DES KOMFORTS";
    const PreloaderFinish = new Event("PreloaderFinish");
    let vue = this;
    let i = 0;
    if (document.readyState == "complete") {
      this.lap = true;
      document.dispatchEvent(PreloaderFinish);
      return
    }
    setTimeout(() => {
      let intervalP = setInterval(() => {
        vue.selected = vue.words[i];
        i++;
        if (i > vue.words.length) {
          this.lap = true;
          document.dispatchEvent(PreloaderFinish);
          clearInterval(intervalP);
          i = 0;
        }
      }, 200);
    }, 700);
  },
  computed: {
    loading() {
      if (this.isLoading == true) {
        return true;
      } else if (this.lap == false) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(246, 242, 230, 1);
}

.text-h3-preloader {
  text-align: center;
  font-size: 3rem !important;
  font-weight: 400;
}

@media (max-width: 600px) {
  .text-h3-preloader {
    font-size: 2rem !important;
  }
}
</style>