<template>
  <v-container fluid class="container-main">
    <v-row justify="center">
      <v-col cols="12">
        <v-parallax width="100%" :height="$vuetify.display.xs ? '400px' : 'auto'" class="parallax-image"
          src="../../assets/img/Home/Parallax.jpg" alt="Mercedes V-klass auto">
          <v-row justify="center" class="parallax-bg ma-0">
            <v-col align-self="center" cols="11" sm="9" md="auto">
              <div class="card-center">
                <div class="card-title fontSFProR px-sm-8">
                  {{ t("$vuetify.home.parallax.title") }}
                </div>
                <div class="px-2">
                  <svg width="15" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H2.5V28H0V0Z" fill="url(#paint0_linear_379_61)" />
                    <path d="M0 15.2727V12.7273H15V15.2727H0Z" fill="url(#paint1_linear_379_61)" />
                    <defs>
                      <linearGradient id="paint0_linear_379_61" x1="1.5" y1="10.5" x2="11" y2="23"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#BD7810" />
                        <stop offset="0.975" stop-color="#FE4F32" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_379_61" x1="1.5" y1="10.5" x2="11" y2="23"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#BD7810" />
                        <stop offset="0.975" stop-color="#FE4F32" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 class="text-left card-subtitle fontSFProL px-4 px-sm-8">
                  {{ t("$vuetify.home.parallax.text.part1") }}
                  <br />
                  {{ t("$vuetify.home.parallax.text.part2") }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-parallax>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: 'Parallax',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({

  })
}
</script>

<style scoped>
.card-subtitle {
  color: #E6E8EB;
  font-size: 1.1rem;
}

.card-title {
  font-size: 2.2rem;
  letter-spacing: 0.4rem;
  background: -webkit-linear-gradient(#FE4F32, #D1931B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.card-center {
  border-radius: 30px;
  border: 1px solid #454545;
  padding: 22px;
  background: rgba(29, 29, 29, 0.3);
  backdrop-filter: blur(5px);
  transition: .5s;
}

.parallax-bg:hover .card-center {
  backdrop-filter: blur(12px);
}

.parallax-bg {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.45) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.45) 100%);
}

.parallax-image {
  border-radius: 25px;
  min-height: 400px;
}

@media (max-width: 600px) {

  .card-title {
    font-size: 1rem;
    letter-spacing: 0.2rem;
  }

  .card-subtitle {
    font-size: 0.7rem;
  }
}
</style>
