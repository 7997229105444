<template>
  <headerTop />
  <Preloader />
  <HelloPage />
  <AllServices />
  <LimousineServices />
  <YachTcharter />
  <VipJet />
  <ExecutiveTransfers />
  <Pricing />
  <Products />
  <PrivateJets />
  <Approach />

  <v-divider class="divider-gray my-15" color="info"></v-divider>

  <GreenTeam />
  <Navigation />
  <FooterComponent />
</template>

<script>
import Preloader from "../components/Preloader.vue";
import headerTop from "../components/header.vue";
import HelloPage from '../components/Service/1.HelloPage.vue';
import AllServices from '../components/Service/2.AllServices.vue';
import LimousineServices from '../components/Service/3.LimousineServices.vue';
import YachTcharter from '../components/Service/4.YachTcharter.vue';
import VipJet from '../components/Service/5.VipJet.vue';
import ExecutiveTransfers from '../components/Service/6.ExecutiveTransfers.vue';
import Pricing from '../components/Service/7.PricingPage.vue';
import Products from '../components/Service/8.Products.vue';
import PrivateJets from '../components/Service/9.PrivateJets.vue';
import Approach from '../components/Approach.vue';
import GreenTeam from '../components/GreenTeam.vue';
import Navigation from '../components/Navigation.vue';
import FooterComponent from '../components/footer.vue';

export default {
  name: 'ServiceView',
  components: {
    Preloader,
    headerTop,
    HelloPage,
    AllServices,
    LimousineServices,
    YachTcharter,
    VipJet,
    ExecutiveTransfers,
    Pricing,
    Products,
    PrivateJets,
    Approach,
    GreenTeam,
    Navigation,
    FooterComponent,
  },
}
</script>

<style scoped>
.divider-gray {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}
</style>
