<template>
  <v-container fluid class="container-main" id="">
    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-row justify="space-between" class="mt-8">
          <v-col cols="12" sm="7" align-self="start">
            <v-row justify="start">
              <v-col cols="1" sm="2" md="1" align-self="center">
                <svg width="100%" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M24.6325 12.3287L23 11.7837V5.5C23 4.96957 22.7893 4.46086 22.4142 4.08579C22.0391 3.71071 21.5304 3.5 21 3.5H14V1.5C14 1.23478 13.8946 0.98043 13.7071 0.792893C13.5196 0.605357 13.2652 0.5 13 0.5C12.7348 0.5 12.4804 0.605357 12.2929 0.792893C12.1054 0.98043 12 1.23478 12 1.5V3.5H5C4.46957 3.5 3.96086 3.71071 3.58579 4.08579C3.21072 4.46086 3 4.96957 3 5.5V11.7837L1.3675 12.3287C0.969064 12.4616 0.62255 12.7165 0.377097 13.0573C0.131644 13.3981 -0.000289408 13.8075 4.18279e-06 14.2275V18.5125C-0.000268386 18.6051 0.0127818 18.6973 0.0387541 18.7862C2.00375 25.6725 10.8038 28.0437 12.5475 28.4487C12.8453 28.5171 13.1547 28.5171 13.4525 28.4487C15.1963 28.0437 23.9963 25.6725 25.9613 18.7862C25.9872 18.6973 26.0003 18.6051 26 18.5125V14.2275C26.0003 13.8075 25.8684 13.3981 25.6229 13.0573C25.3775 12.7165 25.0309 12.4616 24.6325 12.3287ZM14 19.5C14 19.7652 13.8946 20.0196 13.7071 20.2071C13.5196 20.3946 13.2652 20.5 13 20.5C12.7348 20.5 12.4804 20.3946 12.2929 20.2071C12.1054 20.0196 12 19.7652 12 19.5V12.5C12 12.2348 12.1054 11.9804 12.2929 11.7929C12.4804 11.6054 12.7348 11.5 13 11.5C13.2652 11.5 13.5196 11.6054 13.7071 11.7929C13.8946 11.9804 14 12.2348 14 12.5V19.5ZM21 11.1163L13.3163 8.55125C13.111 8.48282 12.889 8.48282 12.6838 8.55125L5 11.1163V5.5H21V11.1163Z"
                    fill="#101010" />
                </svg>
              </v-col>
              <v-col cols="8" sm="10" md="11" align-self="center" class="fontSFProR title-text">
                {{ t(`$vuetify.service.private_jets.title`) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col offset="1" offset-sm="0" cols="10" md="4" lg="3" align-self="center"
            class="description-text fontSFProR">
            {{ t(`$vuetify.service.private_jets.text_right`) }}
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-12 mb-0">
          <v-col cols="12" :sm="((index - 1) % 4 === 0) || ((index - 2) % 4 === 0) ? '5' : 7"
            v-for="(jet, index) in getAllJets" :key="index" class="pa-2">
            <cardJet :jet="jet" :min="((index - 1) % 4 === 0) || ((index - 2) % 4 === 0)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex';
import cardJet from '../cardJet.vue';

export default {
  name: 'PrivateJetsPage',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  components: {
    cardJet,
  },
  data: () => ({

  }),
  computed: {
    ...mapGetters([
      "getAllJets",
    ]),
  },
  mounted() {
    /*  const row = document.querySelector('.row-cards');
     let startY;
     let startScrollLeft;
 
     row.addEventListener('touchstart', (e) => {
       startY = e.touches[0].pageY;
       startScrollLeft = row.scrollLeft;
     });
     row.addEventListener('touchmove', (e) => {
       const currentY = e.touches[0].pageY;
       const deltaY = currentY - startY;
       if (deltaY < 0 && row.scrollLeft + window.innerWidth < row.scrollWidth) {
         //down
         e.preventDefault();
         row.scrollLeft = startScrollLeft - deltaY;
       } else if (deltaY > 0 && row.scrollLeft > 0) {
         //up
         e.preventDefault();
         row.scrollLeft = startScrollLeft - deltaY;
       }
     }); */
  },
}
</script>

<style scoped>
.btn-see-all-2 {
  border-radius: 15px;
  color: #E6E8EB;
  font-size: 1.3rem;
  text-transform: inherit;
  letter-spacing: 0.5px;
}

.card-big-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.svg_absolute {
  position: relative;
  height: 100%;
}

.svg_absolute svg {
  position: absolute;
  right: 0%;
  bottom: 0%;
}

.card-big-col-bottom {
  aspect-ratio: 0.665;
  background: #E6E8EB;
  border-top-left-radius: 15px;
}


.card-big-title {
  letter-spacing: 0px;
  text-align: left;
  color: #FFF;
  text-shadow: 19px 4px 45px rgba(0, 0, 0, 0.80);
  font-size: 1.6rem;
}

.cards-div-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.cards-text {
  color: #E6E8EB;
  font-size: 1.05rem;
  line-height: 1.6rem;
  min-height: 126px;
  white-space: normal;
  text-wrap: balance;
  letter-spacing: 0px;
}

.cards-title {
  color: #E6E8EB;
  font-size: 1.6rem;
  white-space: normal;
  text-wrap: balance;
  letter-spacing: 0px;
}

.card-small-1 {
  background: #101010;
  aspect-ratio: 0.66;
  border-radius: 15px;
  border: 1px solid rgba(154, 154, 155, 0.50);
}

.card-small {
  background: #101010;
  aspect-ratio: 0.67;
  border-radius: 15px;
  border: 1px solid rgba(154, 154, 155, 0.50);
}

.card-big {
  border-radius: 15px;
  height: 100%;
}

.divider-gray {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

.description-text {
  font-size: 0.9rem;
  color: #737475;
}

.text-title {
  color: #343330;
  font-weight: 300;
  font-size: 5.5rem;
  line-height: 7rem;
  text-align: left;
  text-transform: uppercase;
}

.title-text {
  font-size: 1.3rem;
  color: #000000;
}

@media (min-width: 950px) and (max-width: 1150px) {
  .cards-title {
    font-size: 1.4rem;
  }

  .cards-text {
    font-size: 0.85rem;
    line-height: 1.3rem;
  }

}

@media (min-width: 600px) and (max-width: 960px) {
  .text-title {
    font-size: 3.8rem;
    font-weight: 300;
    line-height: 5rem;
  }

  .btn-see-all-2 {
    font-size: 1rem;
  }

}

@media (max-width: 600px) {
  .text-title {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 3rem;
  }

  /*  .row-cards {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-start !important;

  }

  .row-cards {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .row-cards::-webkit-scrollbar {
    display: none;
  } */

  .btn-see-all-2 {
    font-size: 1rem;
  }
}


.svg-animation {
  overflow: visible;
  transform-origin: 50% 50%;
  animation-name: svg-anim-2;
  animation-duration: 18s;
  animation-direction: normal;
  animation-timing-function: linear;
  transform: rotate(0deg);
  animation-iteration-count: infinite;
}

@keyframes svg-anim-2 {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.container-main {
  padding-top: 10vh;
  padding-bottom: 5vh;
}
</style>
<style></style>