<template>
    <v-container :fluid="$vuetify.display.mdAndDown ? true : false" id="Legal-content">
        <v-row justify="center">
            <v-col cols="12" sm="11" md="11">
                <v-row justify="center">
                    <v-col cols="12">
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flat v-show="choosePage == 'terms'">
                                <div class="fontSFProR" v-html="t(`$vuetify.legal.terms`)"></div>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flat v-show="choosePage == 'privacy'">
                                <div class="fontSFProR" v-html="t(`$vuetify.legal.privacy`)"></div>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content  pa-4" flat v-show="choosePage == 'cookies'">
                                <div class="fontSFProR" v-html="t(`$vuetify.legal.cookies`)"></div>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content  pa-4" flat v-show="choosePage == 'impressum'">
                                <div class="fontSFProR" v-html="t(`$vuetify.legal.impressum`)"></div>
                            </v-card>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
    name: "LegalContent",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    computed: {
        choosePage() {
            return this.$route.params.name ?? "terms"
        }
    }
};
</script>

<style>
#Legal-content .cards-content ul {
    margin-left: 1rem;
}

#Legal-content .cards-content a {
    color: #000;
}

#Legal-content .cards-content h1 {
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

#Legal-content .cards-content h2 {
    font-size: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

#Legal-content .cards-content p {
    font-size: 1.15rem;
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
}
</style>
<style scoped>
.cards-content {
    color: #343330;
    background: transparent;
}


.list {
    background: transparent;
}

.list-item {
    background: transparent;
}

.text-title {
    color: #9A9A9B;
    font-size: 1.6rem;
    font-weight: 400;
}

.btn-allp {
    text-transform: inherit;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1.2rem;
}

.container-main {
    padding-top: 15vh;
}

.row-line {
    width: 100%;
}

.title-small2 {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.035rem !important;
}

.title-small {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.04rem !important;
    text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.mh-90 {
    min-height: 69px;
}

.title-big {
    letter-spacing: -0.04rem !important;
    color: #f6f2e6;
    text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
}

.divider-partner {
    border-width: 1px;
    opacity: 1;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, rgba(246, 242, 230, 0.36), rgba(255, 255, 255, 0));
}

@media screen and (max-width: 600px) {
    .title-big {
        font-size: 3rem;
        line-height: 3rem;
    }

    .title-small,
    .title-small2 {
        font-size: 0.85rem !important;
        line-height: 1.1rem !important;
    }

    .row-line {
        height: unset !important;
    }

    .btn-allp {
        font-size: 1rem;
    }

    .mh-90 {
        min-height: 55px;
    }

}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .title-big {
        zoom: 0.8;
    }

    .title-small,
    .title-small2 {
        zoom: 0.8;
    }
}
</style>