<template>
  <Preloader />
  <headerTop />
  <LegalTop />
  <LegalContent />
  <FooterComponent />
</template>

<script>
import Preloader from "../components/Preloader.vue";
import headerTop from "../components/header.vue";
import FooterComponent from '../components/footer.vue';
import LegalTop from "../components/LegalPage/1.LegalTop.vue";
import LegalContent from "../components/LegalPage/2.LegalContent.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LegalPage",
  components: {
    headerTop,
    Preloader,
    LegalTop,
    LegalContent,
    FooterComponent,
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 80px;
  }
}
</style>