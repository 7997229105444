<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <metainfo>
  </metainfo>
</template>

<script>
import { useMeta } from 'vue-meta'
import { useLocale } from "vuetify";

export default {
  name: 'App',
  setup() {
    const locale = useLocale();
    let data = {
      en: {
        title: "Premier VIP Services by GreenWorld: Chauffeurs, Private Jets, Luxury Yachts, and More",
        description: "Experience unparalleled luxury with Green World. From professional chauffeurs and private jet flights to luxurious yachts and VIP transfers, we offer exceptional services tailored to your needs. Over 375 satisfied clients across Europe trust us for our commitment to excellence.",
      },
      de: {
        title: "GreenWorld - Erstklassige VIP-Dienstleistungen: Chauffeure, Privatjets, Luxusyachten und mehr",
        description: "Erleben Sie unvergleichlichen Luxus mit Green World. Von professionellen Chauffeuren und Privatjet-Flügen bis hin zu luxuriösen Yachten und VIP-Transfers bieten wir außergewöhnliche Dienstleistungen, die auf Ihre Bedürfnisse zugeschnitten sind. Über 375 zufriedene Kunden in ganz Europa vertrauen auf unser Engagement für Exzellenz.",
      },
    };
    useMeta({
      htmlAttrs: {
        lang: locale.current.value,
      },
      title: data[locale.current.value].title,
      description: data[locale.current.value].description,
      meta: [
        { vmid: 'description', name: 'description', content: data[locale.current.value].description }
      ]
    })

    /* void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function (sdk) {
      sdk.on("*", (event, payload) => {
        if (event === 'cookies:complete') {
          console.log("vvv");
        }
      })
    }) */
  },
}
</script>
<style lang="scss">
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6,
.text-title,
.fontRaleway {
  font-family: "Raleway", sans-serif !important;
}

html {
  font-size: 15px !important;
  //scroll-behavior: smooth;
  scroll-padding-top: 100px;
  font-family: "Raleway", sans-serif;
}

html,
#app {
  background: #E6E8EB;
  overflow-x: hidden;
}

@media (min-width: 1640px) {
  .v-container {
    max-width: 1600px !important;
  }
}

@media screen and (max-width: 321px) {
  #app {
    zoom: .85;
  }
}

@font-face {
  font-family: "SFProDisplayLight";
  src: url("./assets/fonts/SFProDisplay-Light.ttf");
}

@font-face {
  font-family: "SFProDisplayRegular";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
}

@font-face {
  font-family: "SFProDisplaySemiBold";
  src: url("./assets/fonts/SFProDisplay-SemiBold.ttf");
}

.fontSFProL {
  font-family: "SFProDisplayLight" !important;
}

.fontSFProR {
  font-family: "SFProDisplayRegular" !important;
}

.fontSFProB {
  font-family: "SFProDisplaySemiBold" !important;
}
</style>
<style>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
</style>




<!-- <a href="javascript:openAxeptioCookies()">
      Click here to update your cookies settings
    </a>
    void 0 === window._axcb && (window._axcb = []);
window._axcb.push(function(sdk) {
  sdk.on("*", (event, payload) => {
    if (event === 'cookies:complete') {
      /* Handle consent here */
    }
  })
}) -->